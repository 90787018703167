import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#000",
    },
  },
})

export default theme
